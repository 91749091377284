<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">安防管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/alarmInfo">围墙报警</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="报警时间" prop="createTime">
        <el-date-picker
          v-model="queryModel.createTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group"></el-row>
    <el-table
      :data="tableData"
      style="min-height:400px;"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column prop="createTime" sort-by="create_time" label="报警时间" width="180"></el-table-column>
      <el-table-column prop="message" sort-by="message_" label="报警信息"></el-table-column>
      <!-- <el-table-column label="操作">
        <template slot-scope="{row}">
          <el-button size="mini" disabled type="primary" icon="el-icon-video-camera" @click="handleOpenVideo(row)">查看回放</el-button>
        </template>
      </el-table-column>-->
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <alarmInfo-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></alarmInfo-detail>
  </div>
</template>
<script>
import Constant from "@/constant";
import AlarmInfoDetail from "./alarmInfo-detail";
import alarmInfoApi from "@/api/base/alarmInfo";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "BaseAlarmInfoFenceList",
  data() {
    var self = this;

    return {
      queryModel: {
        id: "",
        createBy: "",
        createTime: "",
        updateBy: "",
        updateTime: "",
        delFlag: "",
        deviceNo: "",
        channelName: "",
        alertType: "",
        type: "1"
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: ""
    };
  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("id", self.queryModel.id);
      formData.append("createBy", self.queryModel.createBy);
      formData.append("createTime", self.queryModel.createTime);
      formData.append("updateBy", self.queryModel.updateBy);
      formData.append("updateTime", self.queryModel.updateTime);
      formData.append("delFlag", self.queryModel.delFlag);
      formData.append("deviceNo", self.queryModel.deviceNo);
      formData.append("channelName", self.queryModel.channelName);
      formData.append("alertType", self.queryModel.alertType);
      formData.append("type", self.queryModel.type);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      alarmInfoApi
        .pageList(formData)
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch(error => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          alarmInfoApi.remove(record.id).then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!"
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map(record => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        alarmInfoApi.batchRemove(idList).then(function(response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!"
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    handleOpenVideo(record) {
      window.open(record.videoUrl);
    },
    queryNotify() {
      var self = this;
      var formData = new FormData();
      formData.append("type", 1);
      alarmInfoApi.queryNotify(formData).then(function(response) {
        var jsonData = response;
        if (jsonData.data.result) {
          self.$notify({
            title: "警告",
            message: jsonData.data.data,
            position: "bottom-right",
            type: "warning",
            duration: 0
          });
        }
      });
    }
  },
  mounted: function() {
    this.changePage(1);
    window.setInterval(() => {
      setTimeout(this.queryNotify(), 0);
    }, 10000);
  },
  components: {
    "alarmInfo-detail": AlarmInfoDetail
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>