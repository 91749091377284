import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/base/alarmInfo/pageList", formData);
}

function create(){
  return request.get(constant.serverUrl + "/base/alarmInfo/create");
}

function edit(id){
  return request.get(constant.serverUrl + "/base/alarmInfo/edit/" + id);
}

function add(formModel){
  return request.post(constant.serverUrl + "/base/alarmInfo/add", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel){  
  return request.post(constant.serverUrl + "/base/alarmInfo/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id){
  return request.post(constant.serverUrl + "/base/alarmInfo/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/base/alarmInfo/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function queryNotify(formModel){
  return request.post(constant.serverUrl + "/base/alarmInfo/queryNotify",formModel);
}

export default {
  pageList,create,edit,add,update,remove,batchRemove,queryNotify
}